// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/Eva.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/Eva.tsx");
  import.meta.hot.lastModified = "1734339076285.5454";
}
// REMIX HMR END

import { useRef, useState } from "react";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
export default function Eva() {
  _s();
  const address = `https://eva.musiclab.si`;
  const encodeBitrate = [{
    id: 0,
    val: '5600k',
    value: '5600'
  }, {
    id: 1,
    val: '4600k',
    value: '4600'
  }, {
    id: 2,
    val: '3600k',
    value: '3600'
  }, {
    id: 3,
    val: '2600k',
    value: '2600'
  }, {
    id: 4,
    val: '2400k',
    value: '2400'
  }, {
    id: 5,
    val: '2200k',
    value: '2200'
  }, {
    id: 6,
    val: '2000k',
    value: '2000'
  }, {
    id: 7,
    val: '1800k',
    value: '1800'
  }, {
    id: 8,
    val: '1600k',
    value: '1600'
  }, {
    id: 9,
    val: '1400k',
    value: '1400'
  }, {
    id: 10,
    val: '1200k',
    value: '1200'
  }, {
    id: 11,
    val: '1000k',
    value: '1000'
  }, {
    id: 12,
    val: '900k',
    value: '900'
  }, {
    id: 13,
    val: '800k',
    value: '800'
  }, {
    id: 14,
    val: '700k',
    value: '700'
  }, {
    id: 15,
    val: '700k',
    value: '700'
  }, {
    id: 16,
    val: '600k',
    value: '600'
  }, {
    id: 17,
    val: '500k',
    value: '500'
  }, {
    id: 18,
    val: '400k',
    value: '400'
  }];
  const [encBitr, setEncBitr] = useState(4);
  const [uplVideo, setUplVideo] = useState({
    name: '',
    src: ''
  });
  const [uplVideoProcessed, setUplVideoProcessed] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [resultVideo, setResultVideo] = useState('');
  const [processing, setProcessing] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [processingError, setProcessingError] = useState('');
  const [processedBitrate, setProcessedBitrate] = useState('');
  const [oldBitrate, setOldBitrate] = useState('');
  const [processedQuality, setProcessedQuality] = useState({
    mse: null,
    gmsd: null,
    psnr: null,
    ssim: null,
    vmaf: null
  });
  const [taskId, setTaskId] = useState(-1);
  const handleFile = e => {
    const f = e.target.files?.[0];
    if (!f) {
      setUploadFile(null);
      return;
    } else if (!f.type.startsWith('video/')) {
      setUploadError('Please upload a valid video file.');
      setUploadFile(null);
      return;
    }
    const videoSize = Math.round(f.size / (1024 * 1024));
    const MAX_SIZE = 200;
    if (videoSize > MAX_SIZE) {
      setUploadError('Video duration exceeds the maximum allowed (30 seconds for UHD).');
      setUploadFile(null);
      return;
    } else {
      setUploadFile(f);
      setUploadError(`Uploading ...`);
      handleUpload(f);
    }
  };
  const handleUpload = uploadFile => {
    if (!uploadFile) {
      setUploadError("Select a file for upload!");
      return;
    }
    const form = new FormData();
    form.append("video", uploadFile);
    axios.post(`${address}/api/`, form, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(async res => {
      console.log("Successful upload");
      await setUploadError("The upload was successful");
      const filename = res.data.split("filename=")[1];
      setUplVideo({
        name: filename,
        src: URL.createObjectURL(uploadFile)
      });
      console.log(uploadFile.name);
    });
  };
  const [range1, setRange1] = useState(0);
  const labels1 = ['100%', '90%', '80%', '70%', '60%'];
  const handleRange = ({
    e,
    setRange
  }) => {
    const percent = (Number(e.target.value) - Number(e.target.min)) / (Number(e.target.max) - Number(e.target.min)) * 100;
    e.target.style.background = `linear-gradient(to right, blue ${percent}%, #666 ${percent}%)`;
    setRange(Number(e.target.value));
  };
  const handleResults = async (i, id, timeout, proc, calc) => {
    if (i < 500) {
      if (!proc && !calc) {
        console.log('not processing and not calculating !!');
      }
      await new Promise(resolve => setTimeout(resolve, timeout));
      console.log(`fetching results i=${i}, ${proc ? 'True' : 'False'}, ${calc ? 'True' : 'False'}`);
      try {
        const response = await axios.get(`${address}/api/result/id=${id}`);
        if (response.data.status == 'done') {
          console.log('done response');
          console.log(response);
          setResultVideo(`${address}/api${response.data.url}`);
          setProcessing(false);
          setProcessedBitrate(response.data.bitrate);
          setOldBitrate(response.data.old_bitrate);
          setProcessedQuality(JSON.parse(response.data.quality));
          console.log(response.data.quality);
          console.log(response.data.bitrate);
          setCalculating(false);
          calc = false;
        } else {
          if (response.data.status == 'calculating quality' && proc) {
            console.log('calculating quality and processing = response');
            setResultVideo(`${address}/api${response.data.url}`);
            setCalculating(true);
            setProcessing(false);
            proc = false;
            calc = true;
            await handleResults(i + 1, id, 5000, proc, calc);
          } else if (response.data.status == 'error') {
            console.log('error in response');
            setProcessing(false);
            setCalculating(false);
            proc = false;
            calc = false;
            setProcessingError('An error occurred while processing the video.');
            setResultVideo(`${address}/api${response.data.url}`);
          } else {
            console.log('else response');
            await handleResults(i + 1, id, 3000, proc, calc);
          }
        }
      } catch (error) {
        console.error('Error fetching results:', error);
        setProcessing(false);
        setCalculating(false);
        setProcessingError('An error occurred while fetching the results.');
      }
    }
  };
  const submit = () => {
    if (!uploadFile) {
      setProcessingError('Please select a video for processing.');
      return;
    }
    setProcessing(true);
    setProcessedBitrate('');
    setProcessedQuality({
      mse: null,
      gmsd: null,
      psnr: null,
      ssim: null,
      vmaf: null
    });
    setResultVideo('');
    setProcessingError('');
    setCalculating(false);
    setUplVideoProcessed(uploadFile);
    console.log('submitting video');
    const json_data = JSON.stringify({
      video: {
        id: -1,
        value: uplVideo.name
      },
      filter: 'bitrate',
      filter_value: range1,
      encode_bitrate: encodeBitrate[encBitr]
    });
    axios.post(`${address}/api/submit`, json_data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(async res => {
      if (res.data.message != '') {
        console.log('on submit response was not empty, processing to false');
        setProcessing(false);
        if (res.data.task_id == 0 && res.data.message == 'Preprocess') {
          setResultVideo(res.data.url);
          setProcessedQuality(res.data.quality);
          setProcessedBitrate(res.data.bitrate);
          setProcessingError('');
        } else {
          setProcessingError(res.data.message);
        }
      } else {
        setTaskId(res.data.task_id);
        await new Promise(resolve => setTimeout(resolve, 20000));
        handleResults(1, res.data.task_id, 3000, true, false);
      }
    });
  };
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const handlePlay = first => {
    if (uplVideoProcessed?.name === uploadFile?.name) {
      handleSync(first);
      requestAnimationFrame(() => {
        videoRef1.current.play();
        videoRef2.current.play();
      });
    } else {
      if (first) {
        videoRef1.current?.play();
      } else {
        videoRef2.current?.play();
      }
    }
  };
  const handlePause = first => {
    if (uplVideoProcessed?.name === uploadFile?.name) {
      handleSync(first);
      requestAnimationFrame(() => {
        videoRef1.current.pause();
        videoRef2.current.pause();
      });
    } else {
      if (first) {
        videoRef1.current?.pause();
      } else {
        videoRef2.current?.pause();
      }
    }
  };
  const handleSync = first => {
    if (first) {
      videoRef2.current.currentTime = videoRef1.current?.currentTime;
    } else {
      videoRef1.current.currentTime = videoRef2.current?.currentTime;
    }
  };
  return <div className="eva">
        <div className='header'>
            <h1>LUCEA LightStream</h1>
        </div>
        <div className="body">
            <div className="videoholder flex">
                {uplVideo.src != '' ? <video key={uplVideo.name} controls muted loop ref={videoRef1} onPlay={() => handlePlay(true)} onPause={() => handlePause(true)}>
                    <source src={uplVideo.src} /></video> : <div className="videofill"></div>}
                {processing ? <div className="videofill">
                <CircularProgress />
                <p className="message">Processing the video please wait</p>
                <button onClick={() => {
            setProcessing(false);
            setCalculating(false);
            setProcessingError('');
            setProcessedQuality({
              mse: null,
              gmsd: null,
              psnr: null,
              ssim: null,
              vmaf: null
            });
            setProcessedBitrate('');
            axios.get(`${address}/api/stop/id=${taskId}`).then(res => {
              console.log(res.data);
            });
          }}>Stop processing</button>
                </div> : resultVideo !== '' ? <video key={resultVideo} controls muted loop ref={videoRef2} onPlay={() => handlePlay(false)} onPause={() => handlePause(false)}>
                    <source src={resultVideo} />
                    Video: {resultVideo}
                </video> : <div className="videofill"><p className="error">{processingError}</p><p>{resultVideo}</p></div>}
            </div>
            <div className="controls">
                <div className="settings flex">
                    <div className="choosevideo flex">
                        <h4>Select Video</h4>
                        <div className="uploadvideo">
                            <input type='file' name='video' onChange={e => handleFile(e)} />
                            {uploadError != '' ? uploadFile != null ? <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                                        <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                                            {uploadError !== 'The upload was successful' && <CircularProgress size={25} />}
                                        </div>
                                        <p style={{
                  color: 'green',
                  marginBottom: '30px'
                }}>{uploadError}</p>
                                    </div> : <p style={{
                color: 'red',
                marginBottom: '30px'
              }}>{uploadError}</p> : <p style={{
                marginBottom: '30px'
              }}>{'     '}</p>}
                        </div>
                    </div>
                    
                    <div className="filters inline">
                        <div className="slider flex">
                            <div className="range">
                                <h4>Perceptual Quality</h4>
                                <input type="range" value={range1} min='1' max='5' step='1' onChange={e => handleRange({
                  e: e,
                  setRange: setRange1
                })} />
                                <div className="range-labels flex">
                                    {labels1.map(i => <label key={i}>{i}</label>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bitrate-combo flex">
                        <h4>Reduce Video Size</h4>
                        <select onChange={e => setEncBitr(Number(e.target.value))} value={encBitr}>
                            {encodeBitrate.map(item => <option key={item.id} value={item.id}>{item.val}</option>)}
                        </select>                    
                    </div>
                </div>
                <div className="buttonborder flex">
                    <div className="start">
                    <button onClick={!processing ? submit : () => {}}>
                        <div className="light" style={{
                backgroundColor: processing ? 'orange' : 'green'
              }} /><span>PERCEPTUAL ENCODING</span>
                    </button>
                </div>
                </div>
                {/*<Metrics calculating={calculating} bitrate={processedBitrate} quality={Quality.fromJson(processedQuality)} name={'Metrics'} oldBitrate={oldBitrate}/>*/}
            </div>
        </div>
      </div>;
}
_s(Eva, "DeFf5m+ikbjkp3Obyw98hJjcfQ8=");
_c = Eva;
var _c;
$RefreshReg$(_c, "Eva");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;